<template>
  <div class="hk_page_routerview">
    <!-- <div class="hk_page_title">工作台</div> 
    <div class="hk_page_wrap">功能开发中~</div>-->
    <div class="workbench-wrap">
      <div class="workbench-section">
        <div class="ws-title">快捷填写入口——开发中</div>
        <div class="ws-main fast-main">
          <div class="fast-item fast-yellow">新生登记表</div>
          <div class="fast-item fast-green">转班登记表</div>
          <div class="fast-item fast-blue">调课登记表</div>
          <div class="fast-item fast-purple">退费申请表</div>
        </div>
      </div>

      <div class="workbench-section">
        <div class="ws-title">待完成任务——开发中
          <div class="ws-title-right">
            <span class="tag-icon iconfont icon-task"></span> 任务中心
          </div>
        </div>
        <div class="ws-main">
          <div class="task-list">
            <div class="task-item">
              <div class="item-tag">待移除微信群</div>
              <div class="item-txt">17636680178 学员的 24《极简欧洲史》·周六班-1</div>
              <div class="item-operate">
                <el-radio-group v-model="rdRemove">
                  <el-radio :label="'false'">未移除</el-radio>
                  <el-radio :label="'true'">已移除</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="task-item">
              <div class="item-tag">其它tag</div>
              <div class="item-txt">17636680178 学员的 24《极简欧洲史》·周六班-1</div>
              <div class="item-operate">查看处理</div>
            </div>
            <div class="task-item">
              <div class="item-tag">classin待插班</div>
              <div class="item-txt">17636680178 学员的 24《极简欧洲史》·周六班-1</div>
              <div class="item-operate">查看处理</div>
            </div>
          </div>
          <div class="tast-empty">
            <el-empty description="暂无任务"></el-empty>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rdRemove: ''
    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.workbench-wrap {
  .workbench-section {
    background: #fff;
    border-radius: 2px;
    margin: 15px;
    .ws-title {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      border-bottom: 1px solid #d8d8d8;
      display: flex;
      padding: 16px 24px;

      .ws-title-right {
        flex: 1;
        text-align: right;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        .tag-icon {
          font-size: 16px;
          color: #eccb5e;
        }
      }
    }
    .ws-main {
      padding: 0 24px 15px 24px;

      .task-list {
        .task-item {
          display: flex;
          align-items: center;
          padding: 15px 0;
          font-size: 14px;
          color: #333;
          line-height: 22px;
          .item-tag {
            background: #f08d00;
            border-radius: 4px;
            padding: 5px 8px;
            color: #fff;
          }
          .item-txt {
            flex: 1;
            padding: 0 8px;
          }
          .item-operate {
            color: #f08d00;
          }
        }
      }
    }
    .fast-main {
      padding-top: 15px;
      display: flex;
      align-items: center;
      // align-content: space-around;
      justify-content: space-between;
      .fast-item {
        flex: 1;
        // height: 70px;
        border-radius: 10px;
        background: #d8d8d8;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        padding: 24px 0 24px 20px;
        margin-right: 20px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
      .fast-yellow {
        background: #f7b500;
      }
      .fast-green {
        background: #44d7b6;
      }
      .fast-blue {
        background: #0091ff;
      }
      .fast-purple {
        background: #6236ff;
      }
    }
  }
}
</style>